import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ArticleComponent } from './containers/article/article.component';
import { HomeComponent } from './containers/home/home.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialManifestModule } from '../material-manifest/material-manifest.module';
import { NewsComponent } from './containers/news/news.component';
import { EventsComponent } from './containers/events/events.component';
import { ClubsComponent } from './containers/clubs/clubs.component';
import { ProfilesComponent } from './containers/profiles/profiles.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { ContactComponent } from './containers/contact/contact.component';
import { PageComponent } from './containers/page/page.component';
import { PartnersComponent } from './containers/partners/partners.component';
import { SearchComponent } from './containers/search/search.component';
import { LoginComponent } from './containers/login/login.component';
import { RegisterComponent } from './containers/register/register.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { ErrorComponent } from './containers/error/error.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountComponent } from './containers/account/account.component';
import { AgmCoreModule } from '@agm/core';
import { NguCarouselModule } from '@ngu/carousel';
import { NewsSectionComponent } from './containers/news-section/news-section.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    MaterialManifestModule,
    ReactiveFormsModule,
    RouterModule,
    AgmCoreModule,
    NguCarouselModule,
  ],
  declarations: [
    AccountComponent,
    ArticleComponent,
    HomeComponent,
    NewsComponent,
    NewsSectionComponent,
    EventsComponent,
    ClubsComponent,
    ProfilesComponent,
    ProfileComponent,
    ContactComponent,
    PageComponent,
    PartnersComponent,
    SearchComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ErrorComponent,
  ]
})
export class TemplateModule { }

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  HostListener,
} from "@angular/core";

interface CarouselData {
  type: string;
  images?: any[];
  items?: CarouselItem[];
}

interface CarouselItem {
  image: string;
  mobileImage: string;
  title: string;
  subTitle: string;
  link: string;
}

@Component({
  selector: "bs-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent {
  @Input() data: CarouselData;

  public screenWidth: any;
  public currentMargin = 0;
  public currentPosition = 0;

  constructor() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  public hasNext() {
    return this.currentPosition < this.data.items.length - 1;
  }

  public hasPrevious() {
    return this.currentPosition > 0;
  }

  public next() {
    this.currentPosition++;
    this.currentMargin = this.currentPosition * 100;
  }

  public previous() {
    this.currentPosition--;
    this.currentMargin = this.currentPosition * 100;
  }

  public getImage(item) {
    const image = this.screenWidth <= 640 && item.mobileImage
      ? item.mobileImage
      : item.image;
    return image;
  }
}

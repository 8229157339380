import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';
import { distinctUntilChanged } from 'rxjs/operators';

interface RegisterResponse {
  jwtToken: string;
}

@Component({
  selector: 'bs-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public form: FormGroup = new FormGroup({});
  public formPassword: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public coreService: CoreService,
  ) {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      newsletterOptIn: new FormControl(false),
    });

    this.formPassword = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public ngOnInit() {
    this.coreService.getUser()
    .pipe(
      distinctUntilChanged()
    )
    .subscribe((res: any) => {
      this.form.get('name').setValue(res.name);
      this.form.get('address').setValue(res.address);
      this.form.get('dateOfBirth').setValue(res.dateOfBirth);
      this.form.get('telephone').setValue(res.telephone);
      this.form.get('email').setValue(res.email);
      this.form.get('newsletterOptIn').setValue(res.newsletterOptIn);
      this.form.updateValueAndValidity();
    });
  }

  public action() {
    this.http.put(`${this.coreService.apiUrl}user/me`, this.form.value)
      .subscribe(
        (res: any) => {
          this.snackBar.open('You have successfully updated your profile.', 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }

  public actionPassword() {
    this.http.put(`${this.coreService.apiUrl}user/me`, this.formPassword.value)
      .subscribe(
        (res: any) => {
          this.snackBar.open('You have successfully updated your password.', 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }

}

import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../core/services/core.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bs-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.scss']
})
export class ClubsComponent implements OnInit {

  public lat = 53.487778;
  public lng = -1.475204;
  public zoom = 5;

  public currentType = '';
  public form: FormGroup = new FormGroup({});
  client = environment.client;

  constructor(
    public coreService: CoreService,
  ) {
    this.form = new FormGroup({
      type: new FormControl(this.currentType),
    });
  }

  ngOnInit() { 

  }

}

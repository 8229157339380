import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { distinctUntilChanged, mapTo, switchMap } from 'rxjs/operators';

@Component({
  selector: 'bs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public form: FormGroup = new FormGroup({});

  public page = {
    body: this.coreService.generateBody(''),
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private coreService: CoreService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  public ngOnInit() {

    this.coreService
        .fetchData('pages/?filter[handle][eq]=contact')
        .subscribe((data: any) => {
          if (data.length > 0) {
            this.page.body = this.coreService.generateBody(data[0].body);
          }
        });

    this.form = new FormGroup({
      type: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
    });

    if (this.route.snapshot.params['type'] !== '') {
      this.form.get('type').setValue(this.route.snapshot.params['type']);
      this.form.updateValueAndValidity();
    }
  }

  public action() {
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        distinctUntilChanged(),
        switchMap((token) => {
          return this.http.post(`/api/auth/contact`, {
            ...this.form.value,
          }, {
            headers: {
              'X-Captcha-Token': token
            }
          });
        })
      )
      .subscribe(
        (res: any) => {
          this.snackBar.open('Your message has been successfully sent.', 'Ok');
        },
        (res: any) => {
          this.snackBar.open(res.error.message, 'Ok');
        }
      );
  }

  public loginUser(jwtToken: string) {
    window.localStorage.setItem('jwt', jwtToken);
    this.router.navigate(['/']);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MaterialManifestModule } from '../material-manifest/material-manifest.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { LayoutHomeComponent } from './containers/layout-home/layout-home.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardComponent } from './components/card/card.component';
import { ResultCardComponent } from './components/result-card/result-card.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { CompressedPipe } from './pipes/compressed.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    MaterialManifestModule,
    HttpClientModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LayoutHomeComponent,
    CarouselComponent,
    SidebarComponent,
    CardComponent,
    ResultCardComponent,
    TimeAgoPipe,
    SafePipe,
    ToDatePipe,
    CompressedPipe,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LayoutHomeComponent,
    CarouselComponent,
    SidebarComponent,
    CardComponent,
    ResultCardComponent,
    TimeAgoPipe,
    SafePipe,
    ToDatePipe,
    CompressedPipe,
  ]
})
export class SharedModule { }

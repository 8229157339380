import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';

interface LoginResponse {
  jwtToken: string;
}

@Component({
  selector: 'bs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public coreService: CoreService,
  ) { }

  public ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public action() {
    this.http.post(`${this.coreService.apiUrl}user/login`, this.form.value)
      .subscribe(
        (res: LoginResponse) => {
          (res.jwtToken) ? this.loginUser(res.jwtToken) : this.snackBar.open('An issue has occured.', 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }

  public loginUser(jwtToken: string) {
    window.localStorage.setItem('jwt', jwtToken);
    this.coreService.setUser();
    // this.coreService.emptyStore();
    this.router.navigate(['/']);
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bs-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  public currentType = '';
  public currentYears = [];
  public currentEvents = [];
  public form: FormGroup = new FormGroup({});
  client = environment.client;

  constructor(
    public coreService: CoreService,
  ) {
    this.form = new FormGroup({
      type: new FormControl(this.currentType),
    });
  }

  ngOnInit() {
    this.getEvents(this.currentType);

    this.form.get('type').valueChanges.subscribe(val => {
      this.currentType = val;
      this.getEvents(this.currentType);
    });
  }

  public getEvents(eventType: string) {
    let eventTypeQuery = '';
    if (eventType !== '') {
      eventTypeQuery = `&filter[event_type][contains]=${eventType}`;
    }
    this.coreService
      .fetchData(`events/?filter[start_date_time][gte]=now&sort=start_date_time${eventTypeQuery}`).pipe(
        map((events: any) => {
          const validEvents = [];
          const years = [];

          events.forEach((event) => {
            const temp = event.startDateTime.toString().replace(' ', 'T');
            const eventYear = new Date(temp).getFullYear();
            if (validEvents[eventYear]) {
              validEvents[eventYear].push(event);
            } else {
              validEvents[eventYear] = [];
              validEvents[eventYear].push(event);
              years.push(eventYear);
            }
          });

          return {
            years: years,
            events: validEvents,
          };
        })
      )
      .subscribe((events: any) => {
        this.currentYears = events.years;
        this.currentEvents = events.events;
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'bs-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.scss']
})
export class NewsSectionComponent implements OnInit {

  public currentArticles = [];
  private startArticles = 0;
  public showMoreButtonArticles = true;

  public currentBlogs = [];
  private startBlogs = 0;
  private type;
  public showMoreButtonBlogs = true;
  public unsubscribe$: Subject<any> = new Subject();

  constructor(
    public coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        this.type = params['type'];
        this.loadArticles(this.startArticles);
      }
    );
  }

  public loadMoreArticles() {
    this.startArticles += 16;
    this.loadArticles(this.startArticles);
  }

  public goTo(url) {
    document.location = url;
  }

  private loadArticles(start: number) {
    this.coreService.fetchData(`articles/?filter[type][eq]=${this.type}&sort=-published_date_time&offset=${start}&limit=16`)
    .subscribe((data) => {
      if(data.length === 0) {
        this.showMoreButtonArticles = false;
      }
      this.currentArticles = this.currentArticles.concat(data);
    });
  }

}

export const environment = {
  production: true,
  client: 'britishshooting',
  companyName: 'British Shooting Ltd',
  title: 'British Shooting',
  twitterUsername: 'gbshooting',
  instagramUsername: 'british_shooting',
  social: {
    facebook: 'https://www.facebook.com/BritishShooting',
    youtube: 'https://www.youtube.com/channel/UCxPFGdBjiRK1dp_lQCxvJTQ',
    instagram: 'https://www.instagram.com/british_shooting/',
    twitter: 'https://www.twitter.com/GBShooting',
    googleplus: 'https://plus.google.com/u/0/111925778437176187319/posts',
  },
};

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor() {}

  /**
   * Add JWT Token (if there is one) to a HTTP request.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwtToken = window.localStorage.getItem('jwt');
    if (jwtToken) {
      const requestWithToken = request.clone({
        setHeaders: {
          Authorization: `JWT ${jwtToken}`,
        },
      });
      return next.handle(requestWithToken);
    } else {
      return next.handle(request);
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialManifestModule } from './material-manifest/material-manifest.module';
import { CoreModule } from './core/core.module';
import { TemplateModule } from './template/template.module';
import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';
import { NguCarouselModule } from '@ngu/carousel';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    TemplateModule,
    BrowserAnimationsModule,
    MaterialManifestModule,
    BrowserModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBuFiiCA0Vw9gEJPejO5RzaCLXEfR_CB5k'
    }),
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    NguCarouselModule,
    RecaptchaV3Module,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeP-9EZAAAAALbvu_oapYXWSKWNpGloP9Sv0I71' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

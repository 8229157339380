import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';

interface ForgotResponse {
  message: string;
}

@Component({
  selector: 'bs-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private coreService: CoreService,
  ) { }

  public ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  public action() {
    this.http.post(`${this.coreService.apiUrl}user/forgot-password`, this.form.value)
      .subscribe(
        (res: ForgotResponse) => {
          this.snackBar.open(res.message, 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'bs-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() size: 'small' | 'large' = 'small';

  @Input() type: 'article' | 'link' | 'video' | 'page' |
   'event' | 'profile' | 'partner' | 'club';

  @Input() data: any;

  public sanitisedVideoLink: SafeResourceUrl;
  public sanitisedSrcDoc: SafeHtml;

  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (this.data && this.data.video) {
      this.sanitisedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video);
      // tslint:disable-next-line:max-line-length
      this.sanitisedSrcDoc = this.sanitizer.bypassSecurityTrustHtml(`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;height:100%;object-fit:cover;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${this.data.video}><img alt=yt src=https://img.youtube.com/vi/${this.data.video.replace('https://www.youtube.com/embed/', '')}/hqdefault.jpg><span>▶</span></a>`);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'bs-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit{

  public currentPartners = ['funding', 'official', 'federation'];

  constructor(
    public coreService: CoreService,
    private route: ActivatedRoute,
    ) { }
  
    ngOnInit() {
      this.route.params.subscribe((data) => {
        if (data.type && data.type !== '') {
          this.currentPartners = data.type.split(',');
        }
      });
    }

}

import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';

interface ResetResponse {
  message: string;
}

@Component({
  selector: 'bs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup = new FormGroup({});

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private coreService: CoreService,
  ) { }

  public ngOnInit() {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.min(8)]),
      passwordResetHash: new FormControl(this.route.snapshot.params['passwordResetHash'], [Validators.required]),
    });
  }

  public action() {
    this.http.post(`${this.coreService.apiUrl}user/reset-password`, this.form.value)
      .subscribe(
        (res: ResetResponse) => {
          this.snackBar.open(res.message, 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CoreActionTypes } from './core.actions';

@Injectable()
export class CoreEffects {

  @Effect()
  loadFoos$ = this.actions$.pipe(ofType(CoreActionTypes.LoadCores));

  constructor(private actions$: Actions) {}
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bs-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public twitterFeed$;
  public username;
  public tweets;
  public social;
  public showSideTweet = false;

  constructor(
    private http: HttpClient,
    public coreService: CoreService,
  ) {
    this.social = environment.social;
    this.username = environment.twitterUsername;
    this.twitterFeed$ = this.coreService.fetchData(
      `https://api.digitalglacier.co/tools/feeds/twitter/${environment.twitterUsername}`
    );
    if (environment.client === 'britishshooting') {
      this.showSideTweet = true;
    }
   }

  ngOnInit() { }

}

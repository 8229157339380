import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'bs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public carouselData = {
    items: []
  };

  public currentDateTime = new Date().toISOString();

  constructor(
    public coreService: CoreService,
  ) {}

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.coreService.fetchData('articles/?limit=15&filter[headline_article][eq]=1&sort=-published_date_time,position', true).subscribe((data) => {
      this.carouselData.items = [];
      data.forEach((item: any) => {
        this.carouselData.items.push({
          image: item.image ? this.coreService.getCompressedImage(item.image.data.url) : '',
          mobileImage: item.mobileImage ? this.coreService.getCompressedImage(item.mobileImage.data.url) : null,
          title: item.title,
          subTitle: item.subTitle,
          link: `/article/${item.id}`,
        });
      });
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCore from './store/core.reducer';
import { CoreEffects } from './store/core.effects';
import { CoreService } from './services/core.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { EkirasMarkdownItModule } from 'ekiras-markdown-it';
@NgModule({
  imports: [
    StoreModule.forFeature('core', fromCore.reducer),
    EffectsModule.forFeature([CoreEffects]),
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    EkirasMarkdownItModule.forRoot({
      html: true,
      breaks: true,
    }),
  ],
  providers: [
    CoreService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ]
})
export class CoreModule { }

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'compressed'
})
export class CompressedPipe implements PipeTransform {

    public transform(value: any, type = 'full'): any {

        if (value) {
          if (environment.client === 'britishshooting') {
            const temp = value.toString();
            const typeMatch = temp.match(/\.([^.]*)$/);
            const fileType = typeMatch[1].toLowerCase();
            if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png') {
                return temp
                  .replace(fileType, 'webp')
                  .replace(
                    '/media/',
                    type === 'full' ?
                    '/compressed-media/' :
                    '/compressed-thumbnail-media/'
                  );
            }
          }
            return value;
        } else {
            return null;
        }
    }

}

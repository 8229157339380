import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private titleService: Title,
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
    this.titleService.setTitle(environment.title);
  }

  ngOnInit() { }

}

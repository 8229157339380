import { Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownItService } from 'ekiras-markdown-it';
import { environment } from 'src/environments/environment';

interface Navigation {
  title: string;
  link: string;
  type: string;
  article: string;
  page: string;
  visible: boolean;
  footer: boolean;
}

interface User {
  address: string | undefined;
  created_at: string;
  dateOfBirth: string | undefined;
  email: string;
  id: number;
  name: string;
  telephone: string | undefined;
  updated_at: string;
}

@Injectable()
export class CoreService {

  public apiUrl = '/api/content/';
  // public apiUrl = 'https://api.britishshooting.org.uk/';
  // public apiUrl = 'https://mw1.britishshooting.org.uk/';
  // public apiUrl = 'http://localhost:5432/';

  public endpoints = {
    NAVIGATION: '/api/content/navigation',
    LINKS: '/api/content/links',
    SEARCH: '/api/content/search',
    EVENTS: '/api/content/events',
    CLUBS: '/api/content/clubs',
    PARTNERS: '/api/content/partners',
  };

  public user: User;
  private store: any = {};
  private observables = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: any,
    private markdownItService: MarkdownItService,
  ) {
    if (this.isAuthenticated()) {
      this.setUser();
    }
  }

  public emptyStore() {
    this.store = {};
  }

  public fetchData(key: string, sort = false) {
    if (key in this.store) { // if `data` is available just return it as `Observable`
      return of(this.store[key]);
    } else if (this.observables.hasOwnProperty(key)) { // if  is set then the request is in progress, return it for the ongoing request
      return this.observables[key];
    } else { // create the request, store the `Observable` for subsequent subscribers
      this.observables[key] = this.http.get(this.getUrl(key))
      .pipe(map((response: any) =>  { // when the cached data is available we don't need the `Observable` reference anymore
        this.observables[key] = null;
        (sort) ? this.store[key] = response.sort(this.comparePositions) : this.store[key] = response;
        return this.store[key]; // make it shared so more than one subscriber can get the result
      }),
      share()
      );
      return this.observables[key];
    }
  }

  public generateBody(body: string) {
    const result = this.markdownItService.render(body);
    const boxRegex = /({%box%})/gim;
    const endBoxRegex = /({%boxend%})/gim;
    const imageRegex = /<img/gim;
    const regex = /({{)([ ']*)([a-zA-Z0-9 '\\&amp;]*[a-zA-z0-9])([ '|]*)([a-zA-Z0-9 '\\&amp;]*[a-zA-z0-9])([ '|]*)([a-zA-Z0-9 .:\/]*)([ ']*)([ |']*)([a-zA-Z-]*)([' ]*)([ |']*)([a-zA-Z0-9.:\/]*)([' ]*)(}})/gim;
    let finalResult = result.replace(regex,
      `<a class="link-noshow box $10" href="$13">
          <img class="image" src="$7"/>
          <div class="title">$3</div>
          <div class="sub-title">$5</div>
      </a>
      `);
    finalResult = finalResult.replace(boxRegex, `<div class="outer-box">`);
    finalResult = finalResult.replace(endBoxRegex, `</div>`);
    // if (environment.client === 'britishshooting') {
      finalResult = finalResult.replace(imageRegex, `<img width="100%" height="auto"`);
    // }
    return this.sanitizer.bypassSecurityTrustHtml(finalResult);
  }

  public getUrl(key: string) {
    return key.includes('https://') ? key : `${this.apiUrl}${key}`;
  }

  public isAuthenticated () {
    return window.localStorage.getItem('jwt') ? true : false;
  }

  public getUser() {
    return this.fetchData('user/me');
  }

  public setUser() {
    this.getUser().subscribe((res: User) => {
      this.user = res;
    });
  }

  public getCompressedImage(value: string) {
    if (value && environment.client === 'britishshooting') {
      const temp = value.toString();
      const typeMatch = temp.match(/\.([^.]*)$/);
      const fileType = typeMatch[1].toLowerCase();
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png') {
          return temp
            .replace(fileType, 'webp')
            .replace(
              '/media/',
              '/compressed-media/'
            );
      }
    }
    return value;
  }

  public routeToWithType (routing) {
    if (routing.hasOwnProperty('link')) {
      let link = null;
      if (routing.type === 'link') {
        link = routing.link;
      } else if (routing.type === 'article') {
        link = `/article/${routing.article.id}`;
      }

      if (link !== null) {
        link.startsWith('http') ? this.document.location.href = link : this.router.navigate([link]);
      }
    }
  }

  public comparePositions(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

}

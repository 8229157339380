import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';

interface RegisterResponse {
  jwtToken: string;
}

@Component({
  selector: 'bs-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public form: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private coreService: CoreService,
  ) { }

  public ngOnInit() {
    this.form = new FormGroup({
      recaptcha: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public action() {
    this.http.post(`${this.coreService.apiUrl}user/register`, this.form.value)
      .subscribe(
        (res: RegisterResponse) => {
          this.snackBar.open('You have successfully registered.', 'Ok');
          this.router.navigate(['/login']);
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }

  public loginUser(jwtToken: string) {
    window.localStorage.setItem('jwt', jwtToken);
    this.router.navigate(['/']);
  }

}
